import { moduleLogger } from "../util";
import type { CinemaMountedEvent, CinemaSlideChangedEvent } from "./model";
import { buildTrackingPayloadBenefit, buildTrackingPayloadCinema, TrackingService } from "../tracking";
import { eventQBus as eventQBusGR, type QBus } from "@otto-ec/global-resources/event-q-bus";
import type { Countdown } from "@ft9/countdown";
import type { Feature, FeatureStatus } from "@otto-ec/tracking-bct";

const log = moduleLogger;

/*                                                                                                   */
/*                                                                              */
const MAX_CLEANUP_PERIOD_IN_MILLIS = 20 * 24 * 60 * 60 * 1000;

export class PapercardCinema {
	constructor(
		private readonly countdown: undefined | Countdown = undefined,
		private readonly eventQBus: QBus = eventQBusGR,
		private readonly trackingService: TrackingService = new TrackingService(),
		private sendTracking = true,
	) {}

	/*                                                                                                                   */
	public setSendTracking(b: boolean) {
		this.sendTracking = b;
	}

	init(featureOrder: number) {
		log.info("Initialize papercard-cinema-module");

		this.eventQBus.on("pattern.carousel.mounted", (event?: CinemaMountedEvent) => {
			if (!event) {
				return;
			}
			log.info("cinema mounted");

			const cinemaDom = event.element;
			if (this.sendTracking && cinemaDom.classList.contains("ft9_benefit_cinema__cinema_module")) {
				this.sendLoadedTracking(cinemaDom, event.currentSlides, featureOrder);
				this.registerVisibleTracking(cinemaDom, event.currentSlides, featureOrder);
			}

			if (this.countdown) {
				for (const benefit of this.getBenefitEntries(cinemaDom)) {
					const scarcityLineDom = benefit.getElementsByClassName("benefit-js-countdown") as HTMLCollectionOf<HTMLElement>;
					this.countdown?.cinemaCountdownInit(scarcityLineDom);
				}
			}

			this.initBenefitExpiryCleanup(cinemaDom);
		});

		this.eventQBus.on("pattern.carousel.slide.changed", (event?: CinemaSlideChangedEvent) => {
			if (!event) {
				return;
			}
			log.info("cinema slide changed");

			const cinemaDom = event.element;
			if (cinemaDom.classList.contains("ft9_benefit_cinema__cinema_module") && event.scrollingRight) {
				log.info("Receiving change event from asset for a cinema -> going to track:", { currentSlides: event.currentSlides, scrollingRight: event.scrollingRight, source: event.source });

				const visibleCinemaPayload = buildTrackingPayloadCinema("visible", cinemaDom, featureOrder);
				if (!visibleCinemaPayload) {
					log.info("missing cinema tracking data");
					return;
				}

				const slides = this.getBenefitEntries(cinemaDom);
				const visibleTrackingData: Feature[] = [];

				for (const id of event.currentSlides) {
					const nowVisibleElement = slides[id] as HTMLElement;
					if (nowVisibleElement.dataset.trackingState !== "visible") {
						nowVisibleElement.dataset.trackingState = "visible";
						const childTrackingLabels = buildTrackingPayloadBenefit("visible", nowVisibleElement, visibleCinemaPayload.id);
						if (childTrackingLabels) {
							visibleTrackingData.push(childTrackingLabels);
						} else {
							log.info("missing benefit tracking data for benefit on position", id);
						}
					}
				}

				if (visibleTrackingData.length > 0) {
					this.trackingService.sendEvent({
						name: "next",
						features: [visibleCinemaPayload, ...visibleTrackingData],
					});
				}
			}
		});
	}

	sendLoadedTracking(cinemaDom: HTMLElement, currentVisibleSlides: number[], featureOrder: number) {
		const loadedTrackingData: Feature[] = [];

		const cinemaTracking = buildTrackingPayloadCinema("loaded", cinemaDom, featureOrder);
		if (!cinemaTracking) {
			log.info("missing cinema tracking data");
			return;
		}
		loadedTrackingData.push(cinemaTracking);

		const benefits = this.getBenefitEntries(cinemaDom);
		for (let i = 0; i < benefits.length; i++) {
			const benefitElement = benefits[i] as HTMLElement;

			let status: FeatureStatus = "hidden";
			if (currentVisibleSlides.includes(i)) {
				status = "loaded";
			}

			const benefitTracking = buildTrackingPayloadBenefit(status, benefitElement, cinemaTracking.id);
			if (benefitTracking) {
				loadedTrackingData.push(benefitTracking);
			} else {
				log.info("missing benefit tracking data for hidden benefit on position", i);
			}
		}

		this.trackingService.sendMergeForCinema(loadedTrackingData);
	}

	registerVisibleTracking(cinemaDom: HTMLElement, currentVisibleBenefits: number[], featureOrder: number) {
		this.registerSeenHandlerCallback(cinemaDom, () => {
			const cinemaTracking = buildTrackingPayloadCinema("visible", cinemaDom, featureOrder);
			if (!cinemaTracking) {
				log.info("missing cinema tracking data");
				return;
			}

			const visibleTrackingData: Feature[] = [];
			const benefits = this.getBenefitEntries(cinemaDom);
			for (const id of currentVisibleBenefits) {
				const visibleBenefit = benefits[id] as HTMLElement;
				visibleBenefit.dataset.trackingState = "visible";
				const benefitTracking = buildTrackingPayloadBenefit("visible", visibleBenefit, cinemaTracking.id);
				if (benefitTracking) {
					visibleTrackingData.push(benefitTracking);
				}
			}

			this.trackingService.sendEvent({
				name: "scroll",
				features: [cinemaTracking, ...visibleTrackingData],
			});
		});
	}

	registerSeenHandlerCallback(element: HTMLElement, callback: () => void) {
		/*                                                                   */
		if (window.IntersectionObserver) {
			const observer = new window.IntersectionObserver(
				(e, o) => {
					const entry = e[0];
					if (entry?.isIntersecting) {
						o.unobserve(element);
						callback();
					}
				},
				{ threshold: 0.75 },
			);
			observer.observe(element);
		}
	}

	private initBenefitExpiryCleanup(cinemaDom: HTMLElement) {
		const benefits = this.getBenefitEntries(cinemaDom);
		for (let i = 0; i < benefits.length; i++) {
			const benefitDom = benefits[i] as HTMLElement;
			const activeUntil = benefitDom.dataset.activeUntil;
			if (!activeUntil) {
				continue;
			}

			const end: Date = new Date(activeUntil);
			const delayInMillis: number = Math.min(Math.max(end.getTime() - new Date().getTime(), 1), MAX_CLEANUP_PERIOD_IN_MILLIS);

			window.setTimeout(() => {
				benefitDom.remove();
				const remainingBenefits = this.getBenefitEntries(cinemaDom).length;
				if (remainingBenefits === 0) {
					cinemaDom.closest(".ft9_benefit_cinema__root")?.remove();
				} else {
					this.eventQBus.emit("pattern.carousel.init", ".ft9_benefit_cinema__cinema_module");
				}
			}, delayInMillis);
		}
	}

	private getBenefitEntries(cinemaDom: HTMLElement): HTMLElement[] {
		const elements = cinemaDom.getElementsByClassName("ft9-benefit-paper-card");
		return Array.from(elements).map((element) => element as HTMLElement);
	}
}
